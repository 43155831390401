/*** General Setting ***/
/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,500;1,500&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Playball&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* Box Sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Global Styles */
:root {
  --primary-color: #05f699;
  --secondary-color: #cae3fb;
  --gradientfirst-color: #08a0f3;
  --gradientsecond-color: #0692df;
  --link-color: #066244;
  --font-dark-color: #1f2022;
  --font-light-color: #f9f9f9;
  --body-color: #f8f8f8;
  --background-color: #eef1f3;
}

body {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
  background-color: var(--background-color);
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: var(--link-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  font-weight: normal;
}

ul,
ol {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.commonButton {
  color: var(--font-light-color);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 500px;
  background: linear-gradient(180deg,
      var(--gradientfirst-color) 0%,
      var(--gradientsecond-color) 100%);
  padding: 10px 40px;
  border: 1px solid var(--gradientfirst-color);
  transition: 0.3s ease-in-out all;
}

.commonButton:hover {
  background: var(--font-light-color);
  color: var(--gradientfirst-color);
  transition: 0.3s ease-in-out all;
}

.outlineButton {
  color: var(--font-dark-color);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 500px;
  padding: 10px 40px;
  border: 1px solid var(--font-dark-color);
  transition: 0.3s ease-in-out all;
  background-color: transparent;
}

.outlineButton:hover {
  background-color: var(--font-dark-color);
  color: var(--body-color);
  transition: 0.3s ease-in-out all;
}

.lightButton {
  color: var(--font-dark-color);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 500px;
  background-color: var(--secondary-color);
  padding: 10px 40px;
  border: 1px solid var(--secondary-color);
  transition: 0.3s ease-in-out all;
}

.lightButton:hover {
  background: var(--font-light-color);
  transition: 0.3s ease-in-out all;
}

/* Responsiveness */
@media (min-width: 768px) {
  /* ... styles for medium and larger screens */
}

@media (max-width: 767px) {
  /* ... styles for small screens */
}

/* HEADER CSS */
.topBar {
  /* border: 1px solid #000; */
  /* background: var(--secondary-color); */
  background: #fff;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  padding: 15px 5px;
}

.topBar div.container {
  position: relative;
}

.primaryHeader {
  padding: 20px 5px;
}

.primaryHeader ul {
  gap: 30px;
}

.primaryHeader ul li a {
  position: relative;
  color: var(--font-dark-color);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.3s ease-in-out all;
}

.primaryHeader ul li a:hover {
  color: var(--link-color);
  font-weight: 900;
  transition: 0.3s ease-in-out all;
}

.primaryHeader ul li a::after {
  content: "";
  width: 0px;
  height: 3px;
  background: var(--link-color);
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s ease-in-out all;
}

.primaryHeader ul li:hover a::after {
  content: "";
  width: 40px;
  transition: 0.3s ease-in-out all;
}

.headerLogo {
  position: absolute;
  left: 0px;
  top: -25px;
  height: 140px;
  /* background-color: #fff; */
  padding: 20px;
  padding-left: 0px;
}
@media(max-width: 768px){
 .headerLogo{
  margin-top: 20px;
 }
}
@media (min-width: 768px) and (max-width: 1023px){
  .primaryHeader ul{
    gap: 30px;
    width: 500px;
    flex-wrap: wrap;
    justify-content: end;
  }
}
/* MDSR */
@media (max-width: 576px) {
  .primaryHeader {
    padding-inline: 20px;
  }

  .userHeaderContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: end;
  }
}

@media (max-width: 767px) {
  .hero-sec button {
    font-size: 14px !important;
  }

  .paddingY {
    padding-block: 3em !important;
  }

  .cusTomBorder {
    border: transparent !important;
  }

  .sidebar {
    min-height: auto;
  }
}

footer {
  background-image: url(User/Assets/footerBg.png);
  background-size: cover;
  background-position: center;
}

footer .nav-link {
  color: white;
  font-size: 14px;
}

footer .nav-link:hover {
  color: var(--link-color);
}

.footerStates {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.footerStates li a{
  cursor: pointer;
}

.hero-sec {
  background-image: url(User/Assets/homeHero2.jpg);
  background-size: cover;
  background-position: 100% 70%;
  padding-block: 6em;
}

.hero-sec h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  color: #26478e;
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.paddingY {
  padding-block: 5em;
}

.homePage {
  background-image: url(User/Assets/homeBg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.profile-pic-wrapper {
  left: 50%;
  top: 0rem;
  transform: translate(-50%, -50%);
}

.services-sec .card {
  margin-bottom: 3.8em;
}

.services-sec .card h5 {
  font-weight: 500;
}

.services-sec h6 {
  color: #aaa;
  font-weight: 500;
}

.services-sec .card-footer a {
  font-weight: 500;
}

.serviceDirectoryBtn,
.noticeArchiveBtn {
  border: none;
  background: unset;
  font-weight: 500;
}

.listing-sec .card .card-body {
  background-color: var(--secondary-color);
  text-align: center;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.listing-sec .card {
  border-radius: 1rem;
}

.deathNotice {
  background-color: var(--background-color);
  border-radius: 8px;
}

.creatorLogin {
  background: url(../src/User/Assets/loginBg.png),
    rgb(29, 29, 29) 50% / cover no-repeat;
}

.form-control.admin {
  border-radius: 30px !important;
  border: 1px solid var(--gradientfirst-color);
}

.form-control,
.form-select {
  border-radius: 10px !important;
  border: 1px solid #cdcdcd;
  padding-inline: 18px;
  padding-block: 1em;
  color: black;
  background-color: transparent;
}

.form-control:focus,
.form-select:focus {
  background-color: transparent;
  border-color: transparent !important;
  outline: 0;
  box-shadow: 0 !important;
}

.form-floating label {
  top: -16px;
  color: var(--font-dark-color) !important;
}

.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-select:not(:placeholder-shown)~label::after {
  background-color: #e5ecec;
}

.eyeIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.separators-x {
  position: relative;
  width: 100vw;
  color: var(--font-dark-color);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.separators-x:before,
.separators-x:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 36%;
  height: 1px;
  background-color: #d9d9d9;
}

.separators-x:before {
  left: 0;
}

.separators-x:after {
  right: 0;
}

@media (min-width: 1200px) and (max-width: 1400px) {

  .separators-x:before,
  .separators-x:after {
    width: 34%;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {

  .separators-x:before,
  .separators-x:after {
    width: 30%;
  }
}

@media (min-width: 767px) and (max-width: 991px) {

  .separators-x:before,
  .separators-x:after {
    width: 22%;
  }
}

@media (max-width: 767px) {

  .separators-x:before,
  .separators-x:after {
    background-color: transparent;
  }

  .sidebar {
    min-height: 0 !important;
  }
}

table {
  border: 1px solid var(--gradientfirst-color) !important;
  border-color: var(--gradientfirst-color) !important;
  overflow: hidden;
  border-radius: 1em;
  text-align: center;
}

table thead td {
  background-color: var(--secondary-color) !important;
  font-weight: 600;
}

table td {
  padding-block: 1em !important;
  padding-inline: 1em !important;
  font-weight: 500;
  text-wrap: nowrap;
}

.rdw-editor-main {
  min-height: 200px;
}

.wrapper-class {
  /* padding: 10px; */
  background-color: white;
}

.editor-class {
  background-color: white;
  padding: 1rem;
  border: 1px solid #c4c4c4 !important;
}

.toolbar-class {
  border: 1px solid #c4c4c4 !important;
  margin-bottom: 0 !important;
}

.accordion-flush>.accordion-item,
.accordion-flush>.accordion-item>.accordion-header .accordion-button {
  border-radius: 1em !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.sidebar,
.offcanvas-body {
  background-color: var(--secondary-color) !important;
}

.sidebar ul li a {
  background-color: var(--secondary-color);
  border-radius: 0 !important;
}

.sideBarList .nav-link {
  color: var(--font-dark-color) !important;
  padding-block: 1em;
  padding-inline: 2em !important;
  font-weight: 500;
}

.sideBarList .nav-link.active {
  background-color: white;
  color: var(--font-dark-color);
  border-radius: 20px !important;
}

.modal {
  --bs-modal-border-radius: 1.3em !important;
}

.searchWrapper {
  border: 0 !important;
  border-radius: 0 !important;
  min-height: unset !important;
}

.multiSelectContainer ul {
  transform: translateX(-18px);
  background-color: #ffffff;
}

.custom-tooltip {
  background-color: #fff !important;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 12px !important;
  color: var(--font-dark-color) !important;
}

.rsm-geographies {
  transform: translate(-56%, 82%);
}

.img-box {
  width: 310px;
  height: 300px;
  margin-inline: auto;
  background-color: #e7e7e78c;
  position: relative;
}

.img-box img {
  border: 2px dashed #8d8d8d;
  object-fit: cover;
  height: 100%;
  z-index: 8;
  position: relative;
}

.img-box input {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 9;
}

.img-box button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  z-index: 0;
}

.notesText {
  color: gray;
  font-style: italic;
}

.table-responsive td {
  vertical-align: middle !important;
}

.advertiserProfilePic {
  height: 300px !important;
  width: 300px !important;
}

.advertiserImageGallery {
  gap: 10px;
}

.advertiserImageGallery img {
  width: 33%;
  border-radius: 15px;
}

.deathNoticePicture {
  height: 100px;
  width: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media(max-width: 900px) {
  .advertiserImageGallery {
    flex-direction: column;
  }

  .advertiserImageGallery img {
    width: 100%;
  }
}

.filesUploadAd i {
  cursor: pointer;
}
.uploadedFiles{
  width: 100px;
}

/* MAP CSS  */
:root {
  --color-1: #b5e6e0;
  --color-2: #52cab7;
  --color-3: #cbeeee;
  --color-4: #eff5f4;
}

.rsm-geographies path:nth-child(n) {
  --color-index: calc((n - 1) % 4 + 1);
  fill: var(--color-var, var(--color-1)) !important;
}

.rsm-geographies path:nth-child(n):nth-child(4n+1) {
  --color-var: var(--color-1);
}

.rsm-geographies path:nth-child(n):nth-child(4n+2) {
  --color-var: var(--color-2);
}

.rsm-geographies path:nth-child(n):nth-child(4n+3) {
  --color-var: var(--color-3);
}

.rsm-geographies path:nth-child(n):nth-child(4n+4) {
  --color-var: var(--color-4);
}

.rsm-geographies path:hover {
  fill: #3baca2 !important;
}

.mapColumn {
  border-radius: 20px;
}

/* map csss end */
.contentText {
  text-align: justify !important;
  word-break: break-word !important;
}
.discList {
  list-style: disc !important;
}
.practicalInfoContent ul,
.practicalInfoContent ol {
  list-style: auto ;
}

.fixImage {
  height: 150px !important;
  width:150px  !important;

}
.directoryLogo{
  height: 200px;
}
.featureNoticeImage{
  height: 100px !important;
  object-fit: cover;
}